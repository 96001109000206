import withStyles from '@material-ui/core/styles/withStyles';
import LoadingGif from "assets/img/loading.gif";
import { default as React } from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
const styles = {};


class CustomComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dots: 0
        }
    }
    // changeLoadTxt = () => {
    //     this.setState({
    //         dots: (this.state.dots + 1) % 4
    //     })
    // }
    // componentDidMount() {
    //     setTimeout(() => {
    //         this.interval = setInterval(this.changeLoadTxt, 300)
    //     }, 0);
    // }

    // componentWillUnmount() {
    //     clearInterval(this.interval)
    // }
    render() {
        const { dots } = this.state
        const { color } = this.props;
        return <div style={{ padding: 5 }}>
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', color: color }}>
                <img src={LoadingGif} style={{ width: '1rem', height: '1rem' }} alt='loadinggif' />
                <span style={{ marginLeft: 3, fontStyle: 'italic', color: 'gray' }}>Loading
                {/* {Array(dots).fill('.').join('')} */}
                </span>
            </div>
        </div>;
    }
}
CustomComponent.defaultProps = {
    color: 'black'
}
CustomComponent.propTypes = {
    color: propTypes.oneOf(['white', 'black'])
}
export default withStyles(styles, { withTheme: true })(CustomComponent)