import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import { default as React } from "react";
import { setCookieAllow } from "redux/reducers/appstate";
import { useDispatch } from 'react-redux'
import { Typography } from "@material-ui/core";
const styles = {
  cookieContainer: {
    position: "fixed",
    bottom: 0,
    background: "#fff",
    padding: 20,
    boxShadow: "0 -1px 3px #eaf0f6",
    zIndex: 1000,
    left: 0,
    right: 0
  },
  cookieBody: {
    margin: "0 auto",
    maxWidth: 1000
  }
};
function CookieAllow(props) {

  const dispatch = useDispatch()
  const onDecline = () => {
    dispatch(setCookieAllow('no'));
  };
  const onAccept = () => {
    dispatch(setCookieAllow('yes'));
  };
  const { classes } = props;
  return (
    <div className={classes.cookieContainer}>
      <div className={classes.cookieBody}>
        <Typography sx={{ mb: '2rem' }}>
        このウェブサイトは、お客様のコンピュータにクッキーを保存します。これらのクッキーは、当社のウェブサイトとのやり取りに関する情報を収集し、お客様を記憶するために使用されます。この情報は、お客様の閲覧体験を改善およびカスタマイズし、当ウェブサイトおよび他のメディア上での訪問者に関する分析および指標のために使用されます。使用しているクッキーの詳細については、当社のプライバシーポリシーをご覧ください。クッキーを拒否した場合、このウェブサイトを訪問しても情報は追跡されません。追跡しないというお客様の設定を記憶するために、1つのクッキーがブラウザに保存されます。
          </Typography>
        <Button color="info" size="sm" onClick={() => onAccept()}>
            <Typography>同意する</Typography>
        </Button>
        <Button
          color="danger"
          size="sm"
          simple
          onClick={() => onDecline()}
        >
          <Typography>
          拒否する
          </Typography>
          </Button>
      </div>
    </div>
  );
}
export default (
  withStyles(styles, { withTheme: true })(CookieAllow)
);
