import { Request } from "Utils/Request"
import { API_BASE_URL } from 'config.js';

const putFiles = (params, onSuccess, onFail) => {
    let formData = new FormData();
    formData.append("file", params.file);
    formData.append("public", params.public);
    return Request({ url: API_BASE_URL + 'files', method: 'post', data: formData }, onSuccess, onFail);
}

export default {
    putFiles,
}