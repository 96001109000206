import axios from "axios"
import { store } from "redux/store";
import Toast from "components/Toast/Toast";
import { addLoading, removeLoading } from "redux/reducers/appstate";
import { isLogin } from 'routes/Auth/Auth'
import { API_BASE_URL } from 'config'
axios.defaults.baseURL = API_BASE_URL;

export const Request = (param, success?: any, failed?: any) => {
    const state = store.getState();
    let newparam = param
    if (isLogin()) {
        const header = "Bearer " + state.user.TOKEN;
        newparam.headers = { Authorization: header }
    }
    axios(newparam)
        .then(function (response) {
            // console.log('====== api success ======>', param.url)
            // console.log(response.data)
            // console.log('<====== api call ended =====')
            success(response.data)
        }).catch((error) => {

            // console.log('====== api failed ======>', param.url)
            console.log(error)
            // console.log('<====== api call ended =====')
            if (failed) {
                failed(error)
            } else {
                if (error.response) {
                    if (Array.isArray(error.response.data) && error.response.data.length > 0) {
                        Toast(error.response.status, error.response.data[0].message);
                    } else {
                        Toast(error.response.status, error.response.data.message);
                    }
                } else {
                    Toast(1000);
                }
            }
        })
        .finally(function () {
            // always executed
            store.dispatch(removeLoading());
        });
}
export const CallApi = (params, options = { showError: false }): Promise<any> => {
    const state = store.getState()
    const user = state.user
    let newparams = params
    if (isLogin()) {
        const header = "Bearer " + state.user.TOKEN;
        newparams.headers = { Authorization: header }
    }
    return new Promise((resolve, reject) => {

        axios(newparams).then((res) => {
            resolve(res.data)
        }).catch((error) => {
            if (options.showError) {
                if (error.response) {
                    if (Array.isArray(error.response.data) && error.response.data.length > 0) {
                        Toast(error.response.status, error.response.data[0].message);
                    } else {
                        Toast(error.response.status, error.response.data.message);
                    }
                } else {
                    Toast(1000, JSON.stringify(error));
                }
            }
            reject(error)
        })
    })

}