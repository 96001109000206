import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
// import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import React from 'react';
// const useStyles = makeStyles(theme => ({
//   typography: {
//     padding: theme.spacing(2),
//   },
// }));

function SimplePopover(props) {
  const { reference, anchorOrigin, transformOrigin, content } = props
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <div onClick={handleClick}>
        {reference}
      </div>
      <Popover
        disableScrollLock={true}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {content}
      </Popover>
    </div>
  );
}
SimplePopover.defaultProps = {
  refrence: "Click me",
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  content: <Typography >The content of the Popover.</Typography>
}
SimplePopover.propTypes = {
  reference: PropTypes.node,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
  content: PropTypes.node
}
export default SimplePopover