
import commonStyles from "assets/jss/common/common.jsx"
const styles = {
   ...commonStyles,
    left: {
      float: "left!important",
      display: "block"
    },
    right: {
      padding: "15px 0",
      margin: "0",
      float: "right"
    },
    inlineBlock: {
      display: "inline-block",
      padding: "0px",
      width: "auto"
    },
  
    block: {
      color: "inherit",
      padding: "0.9375rem",
      fontWeight: "500",
      fontSize: "0.75rem",
      textTransform: "uppercase",
      borderRadius: "3px",
      textDecoration: "none",
      position: "relative",
      display: "block"
    },
  
    icon: {
      width: "1.2rem",
      height: "1.2rem",
      top: "3px",
      position: "relative"
    },
    loader: {
      position: "fixed",
      left: "50%",
      top:  "50%",
      transform: "translate(-50%,-50%)",
      zIndex: 10000
    },
    blockUserActivity: {
      width: "100vw",
      height: "100vh",
      background: "#989898",
      opacity: "0.2",
      zIndex: 10000,
      position: "fixed"
    },
  };
  export default styles
