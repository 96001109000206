export const API_BASE_URL = process.env.REACT_APP_PRODUCTION === 'yes' ? 'https://api.actelligent.biz/v1/' : 'https://testapi.actelligent.biz/v1/'
export const MAIN_APP_URL = process.env.REACT_APP_PRODUCTION === 'yes' ? 'https://www.actelligent.co.uk/' : 'https://dev.actelligent.co.uk/'
export const CAPITAL_APP_URL = 'https://app.actelligent-capital.com/'
export const ROLES = {
    retail_investor: 1,
    experienced_investor: 2,
    professional_investor: 3,
    broker_entity: 4,
    corporate_entity: 5,
    industry_expert_entity: 6,
    broker_representative: 7,
    corporate_representative: 8,
    industry_expert_representative: 9,
    admin: 10,
}
export const random_string = "1752363199"
export const is_production = process.env.REACT_APP_PRODUCTION === 'yes'


export const DAYFORMAT = 'DD MMM.YYYY'
export const DATEFORMAT = 'MMMM Do YYYY, h:mm:ss a'