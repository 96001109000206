import { createSlice } from '@reduxjs/toolkit';
import { HEADER_MODE_TYPE } from 'models/App';

interface AppStateType {
  LOADING_COUNT: number;
  REDIRECT_PATH?: any;
  COOKIE_ALLOW: string;
  PREDICT_TOUR_ALLOW: boolean;
  SHOW_COMPANY_DETAIL_TOUR: boolean;
  HEADER_MODE: HEADER_MODE_TYPE;
}
const initialState: AppStateType = {
  LOADING_COUNT: 0,
  REDIRECT_PATH: null,
  COOKIE_ALLOW: 'undefined',
  PREDICT_TOUR_ALLOW: true,
  SHOW_COMPANY_DETAIL_TOUR: true,
  HEADER_MODE: 'ai-solutions'
}

export const slice = createSlice({
  name: 'appstate',
  initialState,
  reducers: {
    addLoading: (state) => {
      return { ...state, LOADING_COUNT: 1 }
    },
    removeLoading: (state) => {
      return { ...state, LOADING_COUNT: 0 }
    },
    setRedirectPath: (state, { payload: data }) => {
      return { ...state, REDIRECT_PATH: data }
    },
    setCookieAllow: (state, { payload: data }) => {
      return { ...state, COOKIE_ALLOW: data }
    },
    setPredictTourAllow: (state, { payload: data }) => {
      return { ...state, PREDICT_TOUR_ALLOW: data }
    },
    setCompanyDetailTourAllow: (state, { payload: data }) => {
      return { ...state, SHOW_COMPANY_DETAIL_TOUR: data }
    },
    setHeaderMode: (state, { payload: data }: { payload: HEADER_MODE_TYPE }) => {
      return { ...state, HEADER_MODE: data }
    }
  },
});
// Exports
export const {
  addLoading,
  removeLoading,
  setRedirectPath,
  setCookieAllow,
  setPredictTourAllow,
  setCompanyDetailTourAllow,
  setHeaderMode
} = slice.actions


export default slice.reducer;