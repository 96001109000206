import {
    primaryColor,
    secondaryColor,
    warningColor,
    dangerColor,
    successColor,
    infoColor,
    roseColor,
    grayColor,
} from "../material-kit-pro-react"

const whiteDarkColor = "#e2e2e2";
const whiteLightColor = "#eee";
const blueDarkColor = '#1d405d';
const blueLightColor = '#2c618b';
const secondaryBackColor = '#0A273317'
export default {

    white: {
        color: 'white !important'
    },
    red: {
        color: 'red'
    },
    secondaryBackground: {
        background: secondaryBackColor
    },
    info: {
        color: `${infoColor} !important`
    },
    success: {
        color: `${successColor} !important`
    },
    danger: {
        color: `${dangerColor} !important`
    },
    gray: {
        color: `${grayColor} !important`
    },
    warn: {
        color: warningColor
    },
    grayBackground: {
        backgroundColor: grayColor
    },
    whiteBackground: {
        backgroundColor: 'white'
    },
    blueDark: {
        color: blueDarkColor
    },
    blueLight: {
        color: blueLightColor
    },
    roseColor, 
    primaryColor,
    secondaryColor,
    warningColor,
    dangerColor,
    successColor,
    infoColor: infoColor,
    blackColor: '#0A2733',
    grayColor: grayColor,
    backgroundColor: '#00acc10d',
    whiteDarkColor,
    whiteLightColor,
    blueDarkColor,
    blueLightColor,
    secondaryBackColor,
    blur: {
        filter: 'blur(3px);',
        userSelect: 'none;'
    },
    headerColor: '#20222bf2'
}