import React, { useEffect } from 'react'
import Anime, { anime } from 'react-anime';
import { isMobile } from 'Utils/Dimension'
export default function (props) {
    useEffect(() => {

        // const svgPath = document.querySelectorAll('#logo_svg path');

        // const svgText = anime({
        //     targets: svgPath,
        //     scale: [0, 1],
        //     easing: 'spring(1, 80, 10, 0)',
        //     duration: 700,
        //     delay: (el, i) => { return i * 100 }
        // });
    }, [])
    const logoWidth = isMobile ? '10rem' : '15rem'
    return <div id='logo_svg'>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width={logoWidth} viewBox="0 0 2533.000000 535.000000"
            preserveAspectRatio="xMidYMid meet">
            <metadata>
                Created by potrace 1.16, written by Peter Selinger 2001-2019
    </metadata>
            <g transform="translate(0.000000,535.000000) scale(0.100000,-0.100000)"
                fill="white" stroke="none">

                <path d="M591 3743 c-321 -763 -586 -1395 -589 -1405 -4 -17 14 -18 448 -16
    l453 3 86 217 86 218 346 0 346 0 6 -82 c4 -46 7 -117 7 -159 0 -42 3 -104 6
    -138 l7 -61 455 0 454 0 -6 62 c-3 35 -47 648 -96 1363 -50 715 -93 1319 -96
    1343 l-5 42 -663 0 -662 0 -583 -1387z m1109 132 l0 -385 -170 0 -170 0 19 48
    c82 207 284 708 288 715 2 4 11 7 19 7 12 0 14 -56 14 -385z"/>
                <path d="M4430 5189 c-208 -16 -424 -70 -565 -141 -350 -176 -580 -554 -710
    -1169 -36 -171 -83 -507 -91 -653 -18 -347 86 -641 279 -782 180 -133 405
    -188 767 -187 285 0 491 26 665 86 65 22 70 26 77 58 11 55 71 719 65 725 -3
    3 -40 -6 -84 -19 -170 -54 -352 -79 -568 -78 -137 0 -315 18 -330 33 -8 8 205
    1198 221 1236 37 89 114 127 293 144 135 14 332 1 510 -33 64 -12 116 -18 120
    -13 15 16 252 675 246 684 -15 24 -245 77 -425 99 -131 16 -343 20 -470 10z"/>
                <path d="M5515 5108 c-9 -42 -125 -686 -125 -697 0 -8 95 -11 306 -11 l306 0
    -6 -27 c-8 -39 -356 -2041 -356 -2047 0 -4 193 -6 430 -6 l430 0 5 23 c8 35
    355 2036 355 2047 0 6 110 10 310 10 l310 0 5 23 c10 39 125 681 125 694 0 10
    -207 13 -1045 13 l-1045 0 -5 -22z"/>

                <path d="M7960 5125 c0 -4 -427 -2438 -486 -2772 l-6 -33 953 0 954 0 59 338
    c32 185 60 349 63 365 l6 27 -523 0 -522 0 5 28 c3 15 15 89 28 165 l22 137
    427 0 426 0 57 323 c32 177 58 325 57 330 0 4 -191 7 -425 7 l-425 0 5 23 c6
    25 55 322 55 331 0 3 208 7 462 8 l462 3 31 100 c18 55 70 218 117 363 l86
    262 -944 0 c-519 0 -944 -2 -944 -5z"/>

                <path d="M10335 5108 c-11 -48 -485 -2752 -485 -2769 0 -18 28 -19 855 -19
    470 0 855 3 855 8 0 4 27 160 60 347 33 187 60 348 60 358 0 16 -27 17 -423
    17 l-423 0 177 1008 c98 554 181 1022 184 1040 l6 32 -430 0 -431 0 -5 -22z"/>
                <path d="M12245 5108 c-3 -13 -115 -644 -248 -1403 l-243 -1380 855 -3 c470
    -1 856 -1 857 1 4 4 124 692 124 711 0 14 -43 16 -423 16 l-423 0 177 1008
    c98 554 181 1022 184 1040 l6 32 -430 0 -431 0 -5 -22z"/>
                <path fill='#fe5000' d="M14475 4514 c-230 -30 -421 -56 -422 -58 -4 -4 -373 -2109 -373
    -2126 0 -7 143 -10 429 -10 l429 0 6 28 c16 78 386 2193 386 2206 0 20 38 23
    -455 -40z"/>
                <path d="M16531 5189 c-148 -10 -312 -38 -436 -74 -114 -33 -292 -118 -371
    -178 -192 -146 -342 -383 -453 -714 -128 -383 -209 -984 -172 -1268 14 -105
    57 -240 102 -322 50 -88 178 -210 269 -254 189 -92 364 -124 675 -123 362 1
    699 59 1024 176 l65 23 126 715 c70 393 130 732 134 753 l7 37 -521 0 c-286 0
    -520 -3 -520 -7 0 -3 -27 -158 -60 -345 -33 -186 -60 -340 -60 -343 0 -3 48
    -5 106 -5 l106 0 -7 -46 c-21 -143 -70 -213 -163 -233 -29 -6 -140 -11 -248
    -11 l-196 0 6 28 c3 15 51 288 106 607 55 319 105 603 112 630 32 141 112 185
    375 207 165 14 387 2 627 -32 81 -12 151 -18 155 -13 5 4 52 132 106 283 54
    151 108 303 121 337 l23 63 -67 20 c-230 67 -689 110 -971 89z"/>
                <path d="M18121 5078 c-20 -99 -481 -2730 -481 -2744 0 -12 136 -14 952 -12
    l952 3 57 330 c32 182 61 345 64 363 l6 32 -521 0 c-409 0 -520 3 -520 13 0 6
    11 77 25 157 14 80 25 148 25 152 0 4 192 9 427 10 l428 3 57 315 c31 173 57
    321 57 328 1 9 -91 12 -425 12 l-426 0 6 28 c3 15 15 83 26 152 11 69 23 137
    26 153 l6 27 458 0 458 0 41 123 c130 397 191 588 191 597 0 7 -307 10 -939
    10 l-940 0 -10 -52z"/>
                <path d="M20261 3737 c-135 -765 -246 -1398 -249 -1404 -3 -10 87 -13 429 -13
    l434 0 105 605 c71 410 108 605 117 605 8 0 11 -26 10 -92 l-2 -93 235 -512
    235 -512 407 -1 406 0 6 27 c12 60 486 2761 486 2771 0 9 -111 12 -429 12
    l-429 0 -5 -22 c-3 -13 -51 -285 -108 -605 -69 -393 -107 -583 -115 -583 -9 0
    -12 27 -11 98 l2 97 -214 465 c-117 256 -223 484 -234 508 l-21 42 -405 0
    -406 0 -244 -1393z"/>
                <path d="M23236 5103 c-10 -47 -126 -691 -126 -697 0 -3 138 -6 306 -6 l306 0
    -6 -32 c-3 -18 -82 -474 -176 -1013 -93 -539 -173 -992 -176 -1008 l-6 -27
    433 2 433 3 180 1037 181 1038 310 2 310 3 62 340 c34 187 62 350 63 363 l0
    22 -1044 0 -1044 0 -6 -27z"/>

                <path fill='#fe5000' d="M5647 968 c16 -29 141 -259 278 -510 l250 -458 35 0 c70 0 13416
    1001 13455 1009 23 5 -3012 9 -7003 10 l-7044 1 29 -52z"/>

                <path fill='#fe5000' d="M14414 5330 c-225 -59 -324 -195 -324 -445 0 -97 9 -128 52 -172 55
    -57 175 -82 393 -83 381 0 518 109 532 426 5 96 3 117 -15 156 -46 104 -150
    138 -416 137 -114 0 -169 -5 -222 -19z"/>
            </g>
        </svg>
    </div>
}