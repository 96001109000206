import customCheckboxRadioSwitch from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";

const style = {
    ...customCheckboxRadioSwitch,
    formControl: {
        position: "relative",
        "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
            color: "#495057"
        }
    },
    label: {
        fontWeight: 'bold',
        color: 'gray',
        marginRight: 4
    }
}
export default style