
import React from "react";
import { Redirect } from "react-router-dom";
import { store } from "redux/store";
import { useHistory } from "react-router-dom";
import commonStyle from "assets/jss/common/common";
import Button from 'components/CustomButtons/Button';
import PropTypes from 'prop-types'
import { login as loginAction, logout as logoutAction } from 'redux/reducers/user'
import { setHeaderMode, setRedirectPath } from 'redux/reducers/appstate'

export const isLogin = () => {
  const user = store.getState().user;
  const now = Date.now();

  if (user.IS_LOGGED_IN && user.EXPIRES_ON > now) {
    return true
  } else {
    return false
  }
}
export const login = (data) => {
  store.dispatch(loginAction(data))
}
export const logout = () => {
  store.dispatch(logoutAction())
}
export const getProfile = () => {
  const state = store.getState();
  return state.user.profile
}
export const RedirectAuth = (history, path = '/auth/login') => {
  const current_location = window.location.href
  store.dispatch(setRedirectPath(current_location))
  history.push(path)
}
function Auth(props) {

  if (!isLogin()) {
    return <Redirect to={"/auth/login"} />
  }
  return props.children;
}

export const AuthView = (props) => {
  const history = useHistory()
  const { title } = props
  if (!isLogin()) {
    return <div style={commonStyle.textLeft}>
      <Button simple color={'danger'}
        onClick={() => RedirectAuth(history)}
      >
        {title}
      </Button>
    </div>
  } else {
    return props.children
  }
}

AuthView.defaultProps = {
  title: 'Please Log in Or Sign up to view'
}
AuthView.propTypes = {
  title: PropTypes.node
}
export default (Auth);
