import Apis from 'apis';
import React from 'react'
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { refreshToken } from 'redux/reducers/user';
import { isLogin } from 'routes/Auth/Auth';
function RefreshToken(props) {

    const refresh_interval = 1000 * 60
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    useEffect(() => {
        const intervalId = setInterval(() => {
            refreshToken();
        }, refresh_interval);
        return () => {
            clearInterval(intervalId)
        }
    }, [])
    const refreshToken = () => {
        const now = Date.now();
        const space = user.EXPIRES_ON - now

        if (isLogin() && !user.REMEMBER_ME && (space < refresh_interval)) {
            console.log('===>I am refreshing token')
            Apis.auth.putAuth({}, (response) => {
                dispatch(refreshToken(response))
            })
        }
    };

    return <div></div>
}

export default (RefreshToken);
