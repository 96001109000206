import { createTheme } from '@material-ui/core/styles'
import {
  primaryColor,
  secondaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
} from "./material-kit-pro-react"

const theme = createTheme({
  palette: {
    primary: { main: "#00acc1" },
    secondary: { main: "#4caf50" },
    info: { main: "#00acc1" },
    white: { main: '#FFFFFF' }
  },
  colors: {
    primaryColor,
    secondaryColor,
    warningColor,
    dangerColor,
    successColor,
    infoColor,
    roseColor,
    grayColor
  },
  typography: { useNextVariants: true }
});
export default theme;
