import { Request } from "Utils/Request"
import { API_BASE_URL } from 'config.js';

const putAuth = (params, onSuccess, onFail) => {
    return Request({ url: API_BASE_URL + 'auth', method: 'put', data: params }, onSuccess, onFail);
}
const postAuth = (params, onSuccess, onFail) => {
    return Request({ url: API_BASE_URL + 'auth', method: 'post', data: params }, onSuccess, onFail);
}

export default {
    putAuth,
    postAuth
}