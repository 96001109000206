import { createSlice } from '@reduxjs/toolkit';
import { SignUpDataType } from 'models/SignUp';


interface FormDataType {
  // signup form data
  SIGNUP: any,
  SIGNUP_SESSION: {
    email: string,
    magic: string
  },
  SIGNUP_PROGRESS: any,
  QUESTIONNAIRE: any,
  QUESTIONNAIRE_GRADE: any,
  SIGNUP_THEME_TITLES: any[],
  ACCREDITED_INVESTOR_DATA: {
    client_names: string,
    is_first_applicant: boolean,
    is_second_applicant: boolean,
    sig_file_first_id: number,
    sig_file_second_id: number,
    date_of_birth_first: number,
    date_of_birth_second: number,
    legal_name1: string,
    legal_name2: string
  },
  // company search txt
  company_search_txt: string
  // build your own portfolio data
  BUILD_OWN_PORTFOLIO_DATA: any
}
const initialState: FormDataType = {
  // signup form data
  SIGNUP_SESSION: null,
  SIGNUP_PROGRESS: {
    currentStep: 'AuthInfoStep',
    completedSteps: []
  },
  SIGNUP: null,
  QUESTIONNAIRE: null,
  QUESTIONNAIRE_GRADE: null,
  SIGNUP_THEME_TITLES: [],
  ACCREDITED_INVESTOR_DATA: null,
  // company search txt
  company_search_txt: '',
  BUILD_OWN_PORTFOLIO_DATA: null
}

export const slice = createSlice({
  name: 'formdata',
  initialState,
  reducers: {
    saveSignup: (state, { payload: data }) => {
      return { ...state, SIGNUP: data }
    },
    saveSignupThemeTitles: (state, { payload: data }) => {
      return { ...state, SIGNUP_THEME_TITLES: data }
    },
    saveQuestionnaire: (state, { payload: data }) => {
      return { ...state, QUESTIONNAIRE: data }
    },
    saveQuestionnaireGrade: (state, { payload: data }) => {
      return { ...state, QUESTIONNAIRE_GRADE: data }
    },
    saveCompanySearchTxt: (state, { payload: data }) => {
      return { ...state, company_search_txt: data }
    },
    saveAccreditedInvestorData: (state, { payload: data }) => {
      return { ...state, ACCREDITED_INVESTOR_DATA: data }
    },
    saveSignupProgress: (state, { payload: data }) => {
      return {
        ...state, SIGNUP_PROGRESS: {
          ...state.SIGNUP_PROGRESS,
          ...data
        }
      }
    },
    saveSignupSession: (state, { payload: data }) => {
      return { ...state, SIGNUP_SESSION: data }
    },
    saveBuildOwnPortfolioData: (state, { payload: data }) => {
      return { ...state, BUILD_OWN_PORTFOLIO_DATA: data }
    },

  },
});
// Exports
export const {
  saveSignup,
  saveSignupThemeTitles,
  saveQuestionnaire,
  saveQuestionnaireGrade,
  saveCompanySearchTxt,
  saveAccreditedInvestorData,
  saveSignupProgress,
  saveSignupSession,
  saveBuildOwnPortfolioData
} = slice.actions


export default slice.reducer;