import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga'

import {
    FLUSH, PAUSE,
    PERSIST, persistReducer, persistStore, PURGE,
    REGISTER, REHYDRATE
} from 'redux-persist';

import appdataReducer from './reducers/appdata';
import appstateReducer from './reducers/appstate';
import formdataReducer from './reducers/formdata';
import userReducer from './reducers/user';
import paymentReducer from './reducers/payment';
import rootSaga from "./sagas"

const persistConfig = {
    key: 'root',
    storage: storage
};
const rootReducer = combineReducers({
    appstate: persistReducer({ key: 'appstate', storage: storage, }, appstateReducer),
    appdata: persistReducer({ key: 'appdata', storage: storage, }, appdataReducer),
    formdata: persistReducer({ key: 'formdata', storage: storage, }, formdataReducer),
    user: persistReducer({ key: 'user', storage: storage, }, userReducer),
    payment: persistReducer({ key: 'payment', storage: storage, }, paymentReducer)
})
const sagaMiddleware = createSagaMiddleware()


const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    // middleware: [thunk],
    middleware: [sagaMiddleware]

});
sagaMiddleware.run(rootSaga)

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof rootReducer>
