import { isMobile } from "Utils/Dimension";

const productStyle = {
  section: {
    paddingTop: isMobile ? '2rem' : '5rem',
    textAlign: "center"
  },
  largeSection: {
    paddingTop: isMobile ? '4rem' : '7rem',
    textAlign: "center"
  },
  extraLargeSection: {
    paddingTop: isMobile ? '6rem' : '10rem',
    textAlign: "center"
  },
};

export default productStyle;
