import { put, select, } from 'redux-saga/effects'
import axios from "axios"
import { isLogin } from "routes/Auth/Auth"
import { ErrorHandler } from "Utils/ErrorHandler"
import { addLoading, removeLoading } from 'redux/reducers/appstate'

import { API_BASE_URL } from 'config'
axios.defaults.baseURL = API_BASE_URL;

export default function* CALLAPI(params) {
    const state = yield select()
    let newParams = params
    if (isLogin()) {
        const header = "Bearer " + state.user.TOKEN;
        newParams.headers = { Authorization: header }
    }
    try {
        yield put(addLoading())
        const result = yield axios(newParams)
        return result.data
    } catch (error) {
        ErrorHandler(error)
    } finally {
        yield put(removeLoading())
    }
}