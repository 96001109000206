import TextField from '@material-ui/core/TextField';
import Apis from "apis";
import commonStyle from 'assets/jss/common/common';
import Loading from 'components/Loading/Loading';
import { default as React, memo, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    input: {
        marginTop: 5
    },

    floatingLabelFocusStyle: {
        background: 'white',
    },
    ticker: {
        color: commonStyle.grayColor
    },
    companyName: {
        color: commonStyle.darkBlue
    },
    companyItem: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
        borderBottom: '0.1px dashed',
        cursor: 'pointer',
        '&:hover': {
            color: commonStyle.infoColor
        }
    }
}));

function SearchCompany(props) {
    const [searchWord, setSearchWord] = useState('')
    const [data, setData] = useState([])
    const limit = 10
    const [loading, setLoading] = useState(false)
    const classes = useStyles()
    const history = useHistory()

    useEffect(
        () => {
            const handler = setTimeout(() => {
                onLoad(searchWord)
            }, 800);

            return () => {
                clearTimeout(handler);
            };
        },
        [searchWord]
    );
    const onLoad = (msg) => {
        console.info('onLoad called here' + searchWord)
        if (msg.length < 1) return
        setLoading(true)
        Apis.investment.getStocks({ limit: limit, search_by: msg, }, (response) => {
            console.info('getStocks completed here' + searchWord)
            setData(response.data)
            setLoading(false)
        }, () => {
            console.info('getStocks error here' + searchWord)

            setLoading(false)
        }, { hideLoading: true })
    }

    const goCompanyPage = (item) => {
        props.onClose()
        let detail_href = `/investment/company/${item.id}`
        history.push(detail_href)
    }
    return <div >
        <div style={{ padding: '0.5rem' }}>

            <TextField
                classes={{
                    root: classes.input, // class name, e.g. `classes-nesting-label-x`
                }}
                fullWidth
                label="Search"
                id='company'
                defaultValue=""
                variant="outlined"
                size="small"
                onChange={(e) => setSearchWord(e.target.value)}
                InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                }}
                value={searchWord}
            />
        </div>
        <div>
            {loading && <Loading />}
            {searchWord.length > 0 && <div>
                {
                    data.map((item, key) => {
                        return <div key={key} className={classes.companyItem}>
                            <div className={classes.ticker}>{item.ticker}</div>
                            <div className={classes.companyName} onClick={() => goCompanyPage(item)} ><b>{item.name}</b></div>
                        </div>
                    })
                }
            </div>}
        </div>

    </div >;
}

export default (memo(SearchCompany))