import { Request } from "Utils/Request"
import { API_BASE_URL } from 'config.js';

const getContribution = (params, onSuccess, onFail) => {
    return Request({ url: API_BASE_URL + 'contributions', method: 'get', params }, onSuccess, onFail);
}
const getContributionPublic = (params, onSuccess, onFail) => {
    return Request({ url: API_BASE_URL + 'contributions/public', method: 'get', params }, onSuccess, onFail);
}

const postContributionViews = (params, onSuccess, onFail) => {
    return Request({ url: API_BASE_URL + `contributions/${params.id}/views`, method: 'post' }, onSuccess, onFail);
}

const getOneContribution = (params, onSuccess, onFail) => {
    return Request({ url: API_BASE_URL + `contributions/${params.id}`, method: 'get' }, onSuccess, onFail);
}


export default {
    getContribution,
    getContributionPublic,
    postContributionViews,
    getOneContribution
}