import { all, call } from "redux-saga/effects"
import appdata from "./appdata";
import payment from "./payment";
import privatedata from './privatedata'

export default function* rootSaga() {
    yield all([
        call(appdata),
        call(privatedata),
        call(payment),
    ])
}