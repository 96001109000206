
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import React, { useState } from "react";
const toastRef = React.createRef();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export const Toaster = (props) => {
  const [snacks, setSnacks] = useState([])


  const addSnack = (message, severity) => {
    const newSnack = {
      message,
      severity,
    }
    setSnacks([...snacks, newSnack])
    setTimeout(() => {
      handleClose(0)
    }, 6000);
  }
  const handleClose = (snack_index) => {
    let newSnacks = [...toastRef.current.snacks]
    newSnacks.splice(snack_index, 1)
    setSnacks(newSnacks)
  }
  return <div
    ref={(r) => {
      toastRef.current = {
        add: addSnack,
        snacks: snacks,
      }
    }}>
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={true}
    >
      <div>
        {snacks.map((sn, key) => {

          return <div key={key} style={{ marginBottom: '1rem' }}>
            <Alert key={key} severity={sn.severity} onClose={() => handleClose(key)}>
              {sn.message}
            </Alert>
          </div>
        })
        }
      </div>
    </Snackbar>

  </div>
}

Toaster.add = (message, severity) => {
  toastRef.current?.add(message, severity);
};


export default function (code, message) {

  let msg = "";
  if (code >= 200 && code < 300) {
    msg = message ? message : "Done successfully!";

    Toaster.add(msg, 'success');

    return
  }
  if (code >= 400 && code < 500) {
    msg = message ? message : "400 Invalid input!";
    if (code === 401) {
      console.error('Authentication error')
      return
    }
    Toaster.add(msg, 'warning');

    return
  }
  if (code >= 500 && code < 600) {
    msg = message ? message : "500 Server error!";
    Toaster.add(msg, 'error');

    return
  }
  if (code > 600) {
    msg = message ? message : "Please check your Internet connection";
    Toaster.add(msg, 'info');

    return
  }
}
