import { createSlice } from '@reduxjs/toolkit';
// Initial State
const initialState = {
  COUNTRIES: [],
  SECTOR_TREES: [],
  SECTORSL1: [],
  REGIONS: [],
  REGIONS_COUNTRIES: [],
  CONTACTUS_TYPES: [],
  THEMES: [],
  METRICS: [],
  FACTORS: [],
  // questionnaire_grade
  QUESTIONNAIRE_GRADE_OPTIONS: [],
  AUMSIZES: [],
  CATEGORIES: [],
  // signup options
  HEAR_FROM_SROUCES: [],
  MAILTYPES: [],
  OCCUPATIONS: [],
  ANNUAL_INCOMES: [],
  TOTAL_NETWORTHS: [],
  WEALTH_SOURCES: [],
  EDUCATIONS: [],
  NO_TIN_REASONS: [],
  USER_INDUSTRIES: [],
};

export const slice = createSlice({
  name: 'appdata',
  initialState,
  reducers: {

    loadPrivateData: (state) => {

    },
    loadInitData: (state) => {

    },
    loadPaymentInitData: (state) => {

    },
    saveCountries: (state, { payload: data }) => {
      return { ...state, COUNTRIES: data }
    },

    saveHearFromSources: (state, { payload: data }) => {
      return { ...state, HEAR_FROM_SROUCES: data }
    },
    saveSectorTrees: (state, { payload: data }) => {
      return { ...state, SECTOR_TREES: data }
    },
    saveSectorsL1: (state, { payload: data }) => {
      return { ...state, SECTORSL1: data }
    },
    saveThemes: (state, { payload: data }) => {
      return { ...state, THEMES: data }
    },
    saveRegions: (state, { payload: data }) => {
      return { ...state, REGIONS: data }
    },
    saveRegionsCountries: (state, { payload: data }) => {
      return { ...state, REGIONS_COUNTRIES: data }
    },
    saveContactUsTypes: (state, { payload: data }) => {
      return { ...state, CONTACTUS_TYPES: data }
    },
    saveMetrics: (state, { payload: data }) => {
      return { ...state, METRICS: data }
    },
    saveFactors: (state, { payload: data }) => {
      return { ...state, FACTORS: data }
    },
    saveQuestionnaireGradeOptions: (state, { payload: data }) => {
      return { ...state, QUESTIONNAIRE_GRADE_OPTIONS: data }
    },
    saveMailingTypes: (state, { payload: data }) => {
      return { ...state, MAILTYPES: data }
    },
    saveOccupation: (state, { payload: data }) => {
      return { ...state, OCCUPATIONS: data }
    },
    saveAnnualIncomes: (state, { payload: data }) => {
      return { ...state, ANNUAL_INCOMES: data }
    },
    saveTotalNetWorths: (state, { payload: data }) => {
      return { ...state, TOTAL_NETWORTHS: data }
    },
    saveWealthSources: (state, { payload: data }) => {
      return { ...state, WEALTH_SOURCES: data }
    },
    saveEducations: (state, { payload: data }) => {
      return { ...state, EDUCATIONS: data }
    },
    saveNoTinReasons: (state, { payload: data }) => {
      return { ...state, NO_TIN_REASONS: data }
    },
    saveUserIndustries: (state, { payload: data }) => {
      return { ...state, USER_INDUSTRIES: data }
    },
    
  }

})
// Exports
export const {
  loadPrivateData,
  loadInitData,
  loadPaymentInitData,
  saveCountries,
  saveSectorTrees,
  saveSectorsL1,
  saveThemes,
  saveRegions,
  saveRegionsCountries,
  saveContactUsTypes,
  saveMetrics,
  saveFactors,
  saveQuestionnaireGradeOptions,

  saveHearFromSources,
  saveMailingTypes,
  saveOccupation,
  saveAnnualIncomes,
  saveTotalNetWorths,
  saveWealthSources,
  saveEducations,
  saveNoTinReasons,
  saveUserIndustries,
} = slice.actions;


export default slice.reducer;