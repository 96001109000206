
import Toast from "components/Toast/Toast";

const ErrorHandler = (e) => {
    if (!e.response) {
        Toast(1000);
    } else {
        Toast(e.response.status, e.response.data.message);
    }
}

export {
    ErrorHandler
}