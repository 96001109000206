import contribution from "./contribution"
import user from "./user"
import event from './event'
import billing from './billing'
import post from "./post"
import investment from "./investment"
import storage from "./storage"
import registration from "./registration"
import auth from "./auth"
import selectors from "./selectors"
export default {
    contribution,
    user,
    event,
    billing,
    post,
    investment,
    storage,
    registration,
    auth,
    selectors
}