import colorStyle from "./colorStyle"
import flexStyle from "./flexStyle"
import fontStyle from "./fontStyle"
import marginStyle from "./marginStyle"
import paddingStyle from "./paddingStyle"
import pageStyle from "./pageStyle"
import sizeStyle from "./sizeStyle"
import sectionStyle from "./sectionStyle"
import componentStyle from './componentStyle'
import controlStyle from './controlStyle'

const style = {
  ...pageStyle,
  ...colorStyle,
  ...marginStyle,
  ...paddingStyle,
  ...flexStyle,
  ...fontStyle,
  ...sizeStyle,
  ...sectionStyle,
  ...componentStyle,
  ...controlStyle,
  pointer: {
    cursor: 'pointer'
  },
  logo: {
    height: '2.5rem'
  },
};
export default style;
