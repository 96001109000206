import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import BusinessCenter from "@material-ui/icons/BusinessCenter";
import Face from "@material-ui/icons/Face";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import VisibilityIcon from '@material-ui/icons/Visibility';
import profileImage from "assets/img/default-avatar.png";
import commonStyles from "assets/jss/common/common";
import classNames from "classnames";
import Button from 'components/CustomButtons/Button';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.jsx';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { CAPITAL_APP_URL } from "config";
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getProfile, isLogin, logout } from "routes/Auth/Auth";

const styles = {
    ...commonStyles,
    topHeader: {
        backgroundColor: commonStyles.blackColor
    },
    tabs: {
        display: 'flex',
        alignItems: 'center',
        height: '4.6rem'
    },
    links: {
        display: 'flex'
    },
    link: {
        paddingRight: 20,
        "& a": {
            color: 'white',
            fontWeight: 400
        },
        "& a:hover": {
            color: commonStyles.infoColor
        }
    },
    links_container: {
        justifyContent: 'flex-end'
    },
    imageDropdownButton: {
        padding: "0 10px",
    },
    profileImg: {
        height: '3rem',
        objectFit: "contain",
        paddingRight: '1rem',
        paddingLeft: '1rem',
        cursor: 'pointer'
    },
    darkBlue: {
        backgroundColor: commonStyles.blueDarkColor,
        transition: 'all 1s'
    },
    list: {
        width: 'max-content'
    },
    topHeaderButton: {
        margin: 0,
        borderRadius: 0,
        height: '4.6rem',
        fontSize: '1.2rem',
        "&:hover": { backgroundColor: commonStyles.successColor },
    }
};
const getProfileImage = (profile) => {
    if (!profile) return profileImage;
    let url = profile.image_url;
    return url ? profile.image_url : profileImage;
};
function TopHeader(props) {
    // const [index, setIndex] = useState(-1)
    const location = useLocation()
    const history = useHistory()
    const [menuIndex, setMenuIndex] = useState(0)

    const { classes } = props
    const pathname = location.pathname


    useEffect(() => {
        getSubMenu()
    }, [location])

    const getSubMenu = () => {
        if (pathname === '/') {
            setMenuIndex('home')
            return
        }
        if (pathname === '/about-us') {
            setMenuIndex('about-us')
            return
        }
        if (pathname === '/services') {
            setMenuIndex('services')
            return
        }
        if (pathname === '/contact') {
            setMenuIndex('contact')
            return
        }
        // if (pathname === '/invest/factor') {
        //     setMenuIndex('factor_investing')
        //     return
        // }
        // if (pathname === '/invest/thematic') {
        //     setMenuIndex('thematic_investing')
        //     return
        // }

        // if (pathname.includes('/investment/company/')) {
        //     setMenuIndex('company_deep_dive')
        //     return
        // }
        setMenuIndex(-1)
    }

    const topHeaderClass = classNames({
        [classes.topHeader]: true,
        [classes.darkBlue]: menuIndex > -1
    })
    const onClicMenu = (menu) => {
        switch (menu) {
            case 'home':
                history.push('/')
                break;
            // case 'thematic_investing':
            //     history.push('/invest/thematic')
            //     break;
            // case 'factor_investing':
            //     history.push('/invest/factor')
            //     break;
            // case 'company_deep_dive':
            //     history.push('/investment/company/22784')
            //     break;
            case 'about_us':
                history.push('/about-us')
                break;
            case 'services':
                history.push('/services')
                break;
            case 'contact':
                history.push('/contact')
                break;

            default:
                setMenuIndex(menu)
        }
    }
    return <div className={topHeaderClass}>
        <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={8} className={classes.tabs}>

                <div style={{ backgroundColor: menuIndex === 'home' ? commonStyles.successColor : 'transparent' }}>
                    <Button simple color='white' onClick={() => onClicMenu('home')} className={classes.topHeaderButton}>ホーム</Button>
                </div>
                <div style={{ backgroundColor: menuIndex === 'about-us' ? commonStyles.successColor : 'transparent' }}>
                    <Button simple color='white' onClick={() => onClicMenu('about_us')} className={classes.topHeaderButton}>当社について</Button>
                </div>
                <div style={{ backgroundColor: menuIndex === 'services' ? commonStyles.successColor : 'transparent' }}>
                    <Button simple color='white' onClick={() => onClicMenu('services')} className={classes.topHeaderButton}>サービス</Button>
                </div>
                <div style={{ backgroundColor: menuIndex === 'contact' ? commonStyles.successColor : 'transparent' }}>
                    <Button simple color='white' onClick={() => onClicMenu('contact')} className={classes.topHeaderButton}>連絡先</Button>
                </div>
                {/* <div style={{ backgroundColor: menuIndex === 'thematic_investing' ? commonStyles.successColor : 'transparent' }}>
                    <Button simple color='white' onClick={() => onClicMenu('thematic_investing')} className={classes.topHeaderButton}>Thematic Investing</Button>
                </div>

                <div style={{ backgroundColor: menuIndex === 'factor_investing' ? commonStyles.successColor : 'transparent' }}>
                    <Button simple color='white' onClick={() => onClicMenu('factor_investing')} className={classes.topHeaderButton}>Factor Analysis</Button>
                </div>
                <div style={{ backgroundColor: menuIndex === 'company_deep_dive' ? commonStyles.successColor : 'transparent' }}>
                    <Button simple color='white' onClick={() => onClicMenu('company_deep_dive')} className={classes.topHeaderButton}>Company Deep Dive</Button>
                </div> */}

            </GridItem>
        </GridContainer>
    </div>;
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
    withStyles(styles, { withTheme: true })(TopHeader)
);