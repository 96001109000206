import { put, call, take, takeLatest, fork } from 'redux-saga/effects'
import {loadPrivateData} from "redux/reducers/appdata"
import CALLAPI from "./api"
import {
  saveFundBalance,
  saveCreditBalance,
  savePaymentHistory,
} from 'redux/reducers/payment'

import {
  saveUserQuestionnaire
} from 'redux/reducers/user'

function* loadFundBalance() {
  const result = yield call(CALLAPI, { url: 'funds/balance', method: 'options' })
  yield put(saveFundBalance(result))
}
function* loadCreditBalance() {
  const result = yield call(CALLAPI, { url: 'credits/balance', method: 'options' })
  yield put(saveCreditBalance(result))
}
function* loadPaymentHistory() {
  const result = yield call(CALLAPI, { url: 'payments', method: 'get' })
  yield put(savePaymentHistory(result))
}
function* loadUserQuestionnaire() {
  const result = yield call(CALLAPI, { url: 'users/questionnaire', method: 'get' })
  yield put(saveUserQuestionnaire(result))
}

function* loadUserPaymentData() {
  yield fork(loadFundBalance)
  yield fork(loadCreditBalance)
  yield fork(loadPaymentHistory)
  yield fork(loadUserQuestionnaire)
}

export default function* loadPrivateDataSaga() {
  yield take(loadPrivateData)
  yield fork(loadUserPaymentData)
}