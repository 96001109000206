import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Toolbar from "@material-ui/core/Toolbar";
import Close from "@material-ui/icons/Close";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import headerStyle from "assets/jss/material-kit-pro-react/components/headerStyle.jsx";
// nodejs library that concatenates classes
import classNames from "classnames";
import Button from 'components/CustomButtons/Button';
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import SearchBox from 'components/Header/SearchBox';
// nodejs library to set properties for components
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import TopHeader from "views/Layout/TopHeader";

const styles = (theme) => ({
  ...headerStyle(theme),
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  subMenus: {
    cursor: 'pointer',
    marginRight: 20,
    color: 'white',
    fontWeight: 400,
    textTransform: 'uppercase',
    "&:hover": {
      borderBottom: '2px solid'
    },
    borderBottom: '2px solid transparent',
    fontSize: '1.2em'
  },

  headerContainer: {
    position: 'fixed',
    left: 0,
    right: 0,
    zIndex: 1200,
  },
  header: {
    height: '5rem'
  }
})
function Header(props) {
  const location = useLocation()
  const [mobileOpen, setMobileOpen] = useState(false)
  const pathname = location.pathname

  useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    } else {

      const { classes, color } = props;
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      window.removeEventListener("scroll", headerColorChange);
    }
    return () => {
      window.removeEventListener("scroll", headerColorChange);
    }
  }, [])


  const headerColorChange = () => {
    const { classes, color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;

    if (!changeColorOnScroll) {

      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      return
    }
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  }

  const { classes, color, brand, absolute, ...rest } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.header]: true
  });
  const isCompanyPage = pathname.includes('/investment/company/')

  return (
    <div className={classes.headerContainer}>
      <Hidden smDown><TopHeader /></Hidden>
      <AppBar className={appBarClasses}>
        <Toolbar className={classes.container}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Link to="/">{brand}</Link>
            {isCompanyPage && <div style={{ marginLeft: '1rem' }}><SearchBox /></div>}

            <Hidden smDown implementation="css">
            </Hidden>
          </div>
          <div>
            <Hidden mdUp>
              <div style={{ display: 'flex', alignItems: 'center', }}>

                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => setMobileOpen(!mobileOpen)
                  }
                >
                  <Menu />
                </IconButton>
              </div>
            </Hidden>
          </div>

        </Toolbar>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={"right"}
            open={mobileOpen}
            classes={{
              paper: classes.drawerPaper
            }}
            onClose={() => setMobileOpen(!mobileOpen)
            }
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setMobileOpen(!mobileOpen)
              }
              className={classes.closeButtonDrawer}
            >
              <Close />
            </IconButton>
            <div className={classes.appResponsive}>
              <HeaderLinks dropdownHoverColor="info" hideDrawer={() => setMobileOpen(false)} {...rest} current_url={location.pathname} />
            </div>
          </Drawer >
        </Hidden >
      </AppBar >
    </div>
  );
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),

  brand: PropTypes.node,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // this.props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // this.props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};


export default withStyles(styles, { withTheme: true })(Header)
