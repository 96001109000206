import { isMobile } from "Utils/Dimension";
import colorStyle from 'assets/jss/common/colorStyle'
export default {
    fontBold: {
        fontWeight: 'bold'
    },
    fontSmall: {
        fontSize: '0.87rem'
    },

    fontNormalBold: {
        fontWeight: 400
    },
    hover: {
        "&:hover": {
            textDecoration: 'underline'
        }
    },
    italic: {
        fontStyle: 'italic'
    },
    h1: {
        fontSize: isMobile ? '2rem' : '3rem',
        lineHeight: '3.5rem',
        marginBottom: '2rem',
        color: colorStyle.blackColor,
        textAlign: 'left'
    },
    h2: {
        lineHeight: '3rem',
        marginBottom: '2rem',
        fontSize: isMobile ? '1.5rem' : '2.5rem',
        color: colorStyle.blackColor

    },
    h3: {
        lineHeight: '2.5rem',
        marginBottom: '1rem',
        fontSize: isMobile ? '1.3rem' : '2rem',
        color: colorStyle.blackColor
    },
    h4: {
        lineHeight: '2rem',
        fontSize: isMobile ? '1.2rem' : '1.5rem',
        marginBottom: '1rem',
        color: colorStyle.blackColor
    },
    h5: {
        lineHeight: '1.5rem',
        marginBottom: '0.5rem',
        fontSize: isMobile ? '1rem' : '1.3rem',
        color: colorStyle.blackColor
    }
}