import { Request } from "Utils/Request"
import { API_BASE_URL } from 'config.js';

const getRegistrationIamsmartQR = (params, onSuccess, onFail) => {
    return Request({ url: API_BASE_URL + 'registration/iamsmart/qr', method: 'get', params: params }, onSuccess, onFail);
}
const getRegistrationIamsmartToken = (params, onSuccess, onFail) => {
    return Request({ url: API_BASE_URL + 'registration/iamsmart/token', method: 'get', params: params }, onSuccess, onFail);
}
const getRegistrationIamsmartProfileRequest = (params, onSuccess, onFail) => {
    return Request({ url: API_BASE_URL + 'registration/iamsmart/profile/request', method: 'get', params: params }, onSuccess, onFail);
}
const getRegistrationIamsmartProfileReturn = (params, onSuccess, onFail) => {
    return Request({ url: API_BASE_URL + 'registration/iamsmart/profile/return', method: 'get', params: params }, onSuccess, onFail);
}
const getRegistrationIamsmartFormRequest = (params, onSuccess, onFail) => {
    return Request({ url: API_BASE_URL + 'registration/iamsmart/form/request', method: 'get', params: params }, onSuccess, onFail);
}
const getRegistrationIamsmartFormReturn = (params, onSuccess, onFail) => {
    return Request({ url: API_BASE_URL + 'registration/iamsmart/form/return', method: 'get', params: params }, onSuccess, onFail);
}
const postRegistrationRequest = (params, onSuccess, onFail) => {
    return Request({ url: API_BASE_URL + 'registration/request', method: 'post', data: params },
        onSuccess, onFail);
}

const postRegistration = (params, onSuccess, onFail) => {
    return Request({ url: API_BASE_URL + 'registration', method: 'post', data: params },
        onSuccess, onFail);
}
const postRegistrationRestore = (params, onSuccess, onFail) => {
    return Request({ url: API_BASE_URL + 'registration/restore', method: 'post', data: params },
        onSuccess, onFail);
}
const putRegistrationEmail = (params, onSuccess, onFail) => {
    return Request({ url: API_BASE_URL + 'registration/email/' + params.token, method: 'put', data: {} },
        onSuccess, onFail);
}
const putRegistrationPassword = (params, onSuccess, onFail) => {
    return Request({ url: API_BASE_URL + 'registration/password/' + params.token, method: 'put', data: { password: params.password } },
        onSuccess, onFail);
}
export default {
    getRegistrationIamsmartQR,
    getRegistrationIamsmartToken,
    getRegistrationIamsmartProfileRequest,
    getRegistrationIamsmartProfileReturn,
    getRegistrationIamsmartFormRequest,
    getRegistrationIamsmartFormReturn,
    postRegistrationRequest,
    putRegistrationEmail,
    postRegistration,
    postRegistrationRestore,
    putRegistrationPassword
}