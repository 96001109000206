
const { width, height } = window.screen;
const isMobile = width >= 320 && width <= 768
const isLarge = width > 768 && width <= 1920
const isExtraLarge = width > 1920
const rem = (remsize) => {
    if (isExtraLarge) {
        return remsize * width / 200
    } else {
        return remsize * 14
    }
}
export {
    width,
    height,
    isMobile, isLarge, isExtraLarge,
    rem
}
// export default function useWindowDimensions() {
//     const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

//     useEffect(() => {
//         function handleResize() {
//             setWindowDimensions(getWindowDimensions());
//         }

//         window.addEventListener('resize', handleResize);
//         return () => window.removeEventListener('resize', handleResize);
//     }, []);

//     const width = windowDimensions.width
//     let deviceType = '4K'
//     switch (width) {
//         case 320:
//             deviceType = 'MobileS'
//             break;
//         case 375:
//             deviceType = 'MobileM'
//             break;
//         case 425:
//             deviceType = 'MobileL'
//             break;
//         case 768:
//             deviceType = 'Tablet'
//             break;
//         case 1024:
//             deviceType = 'Laptop'
//             break;
//         case 1440:
//             deviceType = 'LaptopL'
//             break;

//         default:
//             deviceType = '4K'
//             break;
//     }

//     return {
//         ...windowDimensions,
//         deviceType
//     };
// }
