import AppBar from '@material-ui/core/AppBar';
import withStyles from '@material-ui/core/styles/withStyles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import commonStyle from "assets/jss/common/common";
import Button from 'components/CustomButtons/Button'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
function TabContainer({ children, dir }) {
    return (
        <Typography component="div" dir={dir}>
            {children}
        </Typography>
    );
}
TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired,
};

const styles = {

    hidden: {
        display: 'none'
    }
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}
function CustomTab(props) {

    const [currentId, setCurrentId] = useState(0)
    const [showTab, setShowTab] = useState(true)
    const { classes, collapsible, variant, tabs } = props;
    useEffect(() => {
        window.dispatchEvent(new CustomEvent('resize'))

    }, []);
    return (
        <div >
            <AppBar position="static" color="default">
                <Tabs
                    value={currentId}
                    onChange={(event, value) => {
                        setCurrentId(value)
                    }}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: commonStyle.headerColor
                        }
                    }}
                    variant={variant}
                    scrollButtons="auto"
                >
                    {
                        tabs.map((item, key) => (<Tab label={item.title} key={key}   {...a11yProps(key)} />))
                    }
                </Tabs>
                {collapsible && <Button size='sm' onClick={() => setShowTab(!showTab)} color='info'>{showTab ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</Button>}
            </AppBar>
            <div className={showTab ? classes.show : classes.hidden}>
                {
                    tabs.map((item, key) => {
                        const classname = key !== currentId ? classes.hidden : null
                        return <div className={classname} key={key} ><TabContainer dir="x" >{item.content}</TabContainer></div>
                    })
                }
            </div>

        </div>
    );

}
CustomTab.defaultProps = {
    variant: 'scrollable',
    tabs: [], //{title: '', content: null}
    collapsible: false,
}
CustomTab.propTypes = {
    variant: PropTypes.string,
    tabs: PropTypes.array,
}
export default withStyles(styles)(CustomTab)

