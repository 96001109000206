import { Request } from "Utils/Request"
import { API_BASE_URL } from 'config.js';

const getRiskQuestions = (params, onSuccess, onFail) => {
    return Request({ url: API_BASE_URL + 'risk_questions', method: 'get', params }, onSuccess, onFail);
}
const getGrades = (params, onSuccess, onFail) => {
    return Request({ url: API_BASE_URL + 'grades', method: 'get', params }, onSuccess, onFail);
}
export default {
    getRiskQuestions,
    getGrades
}