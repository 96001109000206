import React, { lazy } from 'react';
const LandingPage = lazy(() => import("views/LandingPage/LandingPage"));
const AboutUs = lazy(() => import('views/Sections/SectionAboutUs.tsx'))
const Services = lazy(() => import('views/Sections/SectionServices.tsx'))
const ContactUs = lazy(() => import('views/Sections/SectionContactUs.jsx'))
const BusinessContinuity = lazy(() => import('views/BusinessContinuity/BusinessContinuity.jsx'))
const PrivacyPolicy = lazy(() => import('views/PrivacyPolicy/PrivacyPolicy.jsx'))
const NotFoundPage = lazy(() => import('views/ErrorPage/NotFound.jsx'))

const TestPage = React.lazy(() => import("views/Dev/TestPage"));

const publicRoutes = [
  // { 
  //   path: "/test", 
  //   name: "Test", 
  //   component: TestPage, 
  // },
  {
    path: '/',
    name: 'Home',
    component: LandingPage
  },
  {
    path: '/about-us',
    name: 'About Us',
    component: AboutUs
  },
  {
    path: '/services',
    name: 'Services',
    component: Services
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactUs
  },
  {
    path: '/legal/privacy-policy',
    name: 'Privacy Policy',
    component: PrivacyPolicy
  },
  {
    path: '/legal/business-continuity',
    name: 'Business Continuity',
    component: BusinessContinuity
  },
  {
    path: '*',
    name: 'Page Not Found',
    component: NotFoundPage
  }
];

const privateRoutes = [

]

export { privateRoutes, publicRoutes };

