import { isMobile } from "Utils/Dimension";
import {
  primaryColor
} from "../material-kit-pro-react";

const componentStyle = {
  table: {
    fontSize: '1.2rem',
    borderCollapse: 'collapse',
    "& td, & th": {
      padding: '0.3rem 1rem',
      border: `1px solid  rgba(0, 0, 0, 0.23)`,
      whiteSpace: 'nowrap'
    },
    "& th": {
      fontWeight: 'bold',
    },

    display: 'block',
    overflow: 'auto',
  },
  table_container: {
    paddingTop: '1rem',
    background: 'white',
    width: isMobile ? '100%' : 'auto',
    overflow: 'auto'
  },
  link: {
    cursor: 'pointer',
    color: primaryColor,

    transition: 'all 0.4s',
    '&:hover': {
      backgroundColor: '#20222bf2',
      color: 'white'
    }
  }
};

export default componentStyle;
