import LinearProgress from '@material-ui/core/LinearProgress';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import withStyles from '@material-ui/core/styles/withStyles';
import Footer from "components/Footer/Footer.jsx";
import Header from "components/Header/Header.jsx";
import LogoComponent from 'components/Logo/LogoComponent';
import { Toaster } from 'components/Toast/Toast';
import { FadeLoader } from "halogenium";
import { default as React, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useLocation } from "react-router";
import Transition from "react-transition-group/Transition";
import { loadPrivateData, loadInitData, loadPaymentInitData } from "redux/reducers/appdata";
import Auth, { isLogin } from 'routes/Auth/Auth';
import RefreshToken from 'routes/Auth/RefreshToken';
import { privateRoutes, publicRoutes } from "routes/index.jsx";
import CookieAllow from "views/CookieAllow/CookieAllow";
import styles from "./Layout.style";
import { setRedirectPath } from "redux/reducers/appstate";
import { Typography } from '@material-ui/core';


function Layout(props) {
  const location = useLocation()
  const [alert] = useState(null)
  const [isTransparentHeader, setIsTransparentHeader] = useState(true)
  const { classes } = props
  const dispatch = useDispatch()
  const appstate = useSelector(state => state.appstate)
  const user = useSelector(state => state.user)
  useEffect(() => {
    loadData();
  }, [])
  useEffect(() => {
    scroll()
    redirectHandler()
    setIsTransparentHeader(location.pathname === '/')
  }, [location])

  const scroll = () => {
    const urlParams = new URLSearchParams(location.search);
    const scrollTo = urlParams.get('scrollTo')
    if (!scrollTo) {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    }
  }
  const redirectHandler = () => {
    const urlParams = new URLSearchParams(location.search);
    const redirect_url = urlParams.get('redirect_url')
    if (redirect_url) {
      if (isLogin()) {
        const userToken = user.TOKEN
        window.location.href = `${redirect_url}?t=${userToken}`
      } else {
        dispatch(setRedirectPath(redirect_url))
      }
    }
  }
  const loadData = () => {
    dispatch(loadInitData())
  }
  const needFooter = () => {
    const path = location.pathname;
    if (path.indexOf('auth/choose_investor_type') > -1) return false

    if (path.indexOf('auth/login') > -1) return false
    if (path.indexOf('auth/signup') > -1) return false
    if (path.indexOf('invest/questionnaire') > -1) return false
    return true
  }
  const transitionStyles = {
    exiting: { transition: `opacity 300ms ease-in-out`, opacity: 0 }
  };

  return (<Suspense fallback={<LinearProgress />}>
    <RefreshToken />
      {alert}
      <Toaster />
      <Transition in={appstate.LOADING_COUNT > 0} unmountOnExit timeout={300}>
        {state => {
          return (
            <div
              style={{
                ...transitionStyles[state]
              }}
              className={classes.blockUserActivity}
            />
          );
        }}
      </Transition>

      {appstate.LOADING_COUNT > 0 && (
        <FadeLoader
          className={classes.loader}
          color="#26A65B"
          size="16px"
          margin="4px"
        />
      )}
      {
        isTransparentHeader && <Header
          color="transparent"
          brand={<LogoComponent />}
          changeColorOnScroll={{
            height: 150,
            color: "info"
          }}
          fixed
        />
      }
      {
        !isTransparentHeader && <Header
          color="info"
          brand={<LogoComponent />}
          fixed
        />
      }
      <Switch>
        {privateRoutes.map((prop, key) => {
          let Component = prop.component
          return <Route
            key={key}
            exact
            path={prop.path}
            render={(props) => <Auth >
              <Component {...props} /></Auth>}
          />;
        })}
        {publicRoutes.map((prop, key) => {
          let Component = prop.component
          return <Route
            key={key}
            exact
            path={prop.path}
            render={(props) => <Component {...props} />}
          />;
        })}
      </Switch>

      {needFooter() && <Footer
        className={classes.footer}
        theme="dark"
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>

                <ListItem className={classes.inlineBlock}>
                  <div>
                    <a
                      href="/legal/privacy-policy"
                      className={classes.block}
                      style={{ fontSize: '1rem' }}
                    >
                      プライバシーポリシー
                    </a>
                  </div>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <div>
                    <a
                      href="/legal/business-continuity"
                      className={classes.block}
                      style={{ fontSize: '1rem' }}
                    >
                      BCP</a>
                  </div>
                </ListItem>
              </List>
            </div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()}. made by Actelligent Team
            </div>
          </div>
        }
      />}

      {appstate.COOKIE_ALLOW === 'undefined' && < CookieAllow />}
  </Suspense>)
}
export default withStyles(styles, { withTheme: true })(Layout);
