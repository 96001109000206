export default {
    textCenter: {
        textAlign: 'center !important;'
    },
    textLeft: {
        textAlign: 'left'
    },
    textRight: {
        textAlign: 'right'
    },
    flex: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center'
    },
    justifyBetween: {
        justifyContent: 'space-between'
    },
    alignCenter: {
        alignItems: 'center'
    }
}