import { Request } from "Utils/Request"
import { API_BASE_URL } from 'config.js';

const getEvents = (params, onSuccess, onFail) => {
    return Request({url: API_BASE_URL+'events', method: 'get', params: params}, onSuccess, onFail);
}
const getPublicEvents = (params, onSuccess, onFail) => {
    return Request({url: API_BASE_URL+'events/public', method: 'get', params: params}, onSuccess, onFail);
}
export default {
    getEvents,
    getPublicEvents
}