import { createSlice } from '@reduxjs/toolkit';
import { UserProfileType } from 'models/User';

type UserReduxType = {
    IS_LOGGED_IN: boolean,
    TOKEN: string,
    REMEMBER_ME: boolean,
    PROFILE: UserProfileType,
    SELECTED_ROLE: any,
    EXPIRES_ON: number,

    EMAIL: string,
    PASSWORD: string,
    USER_QUESTIONNAIRE_DATA: any,
}
const initialState: UserReduxType = {

    IS_LOGGED_IN: false,
    TOKEN: null,
    REMEMBER_ME: false,
    PROFILE: null,
    SELECTED_ROLE: null,
    EXPIRES_ON: 0,

    EMAIL: '',
    PASSWORD: '',
    USER_QUESTIONNAIRE_DATA: null
}


export const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (state, { payload: data }) => {
            const roles = data.roles
            let role = null
            if (roles && roles.length > 0) {
                role = roles[0]
            }
            return {
                ...state,
                IS_LOGGED_IN: true,
                TOKEN: data.access,
                PROFILE: data.user,
                EXPIRES_ON: data.expires_on,
                SELECTED_ROLE: role,
                REMEMBER_ME: data.remember_me
            }
        },

        saveCredential: (state, { payload: data }) => {
            return { ...state, EMAIL: data.email, PASSWORD: data.password }
        },
        changeRole: (state, { payload: data }) => {
            return { ...state, SELECTED_ROLE: data.selectedRole }
        },
        refreshToken: (state, { payload: data }) => {
            return { ...state, TOKEN: data.access, EXPIRES_ON: data.expires_on }
        },
        logout: (state) => {
            return {
                ...state,
                IS_LOGGED_IN: false,
                REMEMBER_ME: false,
                TOKEN: null
            }
        },
        rememberme: (state, { payload: data }) => {
            return { ...state, REMEMBER_ME: data }
        },
        updateProfile: (state, { payload: data }) => {
            return {
                ...state,
                PROFILE: {
                    ...state.PROFILE,
                    ...data
                }
            }
        },
        saveUserQuestionnaire: (state, { payload: data }) => {
            return { ...state, USER_QUESTIONNAIRE_DATA: data }
        },
    },
});
// Exports
export const {
    login,
    saveCredential,
    changeRole,
    refreshToken,
    logout,
    rememberme,
    updateProfile,
    saveUserQuestionnaire
} = slice.actions


export default slice.reducer;