import { put, all, takeLatest, call, take, fork } from 'redux-saga/effects'
import CALLAPI from "./api"
import { isLogin } from 'routes/Auth/Auth';
import {
  savePaymentGateways,
  savePrices,
  saveTransactionTypes,
  savePlans,
  saveFeatures,
  saveDiscounts,
  saveExchangeRates,
  saveMetrixTable,

} from 'redux/reducers/payment'
import {loadPaymentInitData} from 'redux/reducers/appdata'

function* loadPaymentGateways() {
  const result = yield call(CALLAPI, { url: 'payment_gateways', method: 'get' })
  yield put(savePaymentGateways(result))
}
function* loadPrices() {
  const result = yield call(CALLAPI, { url: 'prices', method: 'get' })
  yield put(savePrices(result))
}
function* loadTransactionTypes() {
  const result = yield call(CALLAPI, { url: 'transaction_types', method: 'get' })
  yield put(saveTransactionTypes(result))
}
function* loadPlans() {
  const result = yield call(CALLAPI, { url: 'plans', method: 'get' })
  yield put(savePlans(result))
}
function* loadFeatures() {
  const result = yield call(CALLAPI, { url: 'features', method: 'get' })
  yield put(saveFeatures(result))
}
function* loadDiscounts() {
  const result = yield call(CALLAPI, { url: 'discounts', method: 'get' })
  yield put(saveDiscounts(result))
}
function* loadExchangeRates() {
  const result = yield call(CALLAPI, { url: 'exchange_rates', method: 'get' })
  yield put(saveExchangeRates(result))
}

function* loadMetrixTable() {
  const result = yield call(CALLAPI, { url: 'price_matrix', method: 'get' })
  yield put(saveMetrixTable(result))
}

export default function* paymentSaga() {
  yield take(loadPaymentInitData)

  yield fork(loadPaymentGateways)
  yield fork(loadPrices)
  yield fork(loadTransactionTypes)
  // yield fork(loadPlans)
  yield fork(loadFeatures)
  yield fork(loadDiscounts)
  yield fork(loadExchangeRates)
  yield fork(loadMetrixTable)
}