import { put, call, take, fork } from 'redux-saga/effects'
import { loadInitData } from "redux/reducers/appdata"
import CALLAPI from "./api"
import {
  saveCountries, saveSectorTrees, saveSectorsL1,
  saveRegions,
  saveRegionsCountries,
  saveContactUsTypes,
  saveMetrics,
  saveFactors,
  saveThemes,
  saveQuestionnaireGradeOptions,
  saveHearFromSources,
  saveMailingTypes,
  saveOccupation,
  saveAnnualIncomes,
  saveTotalNetWorths,
  saveWealthSources,
  saveEducations,
  saveNoTinReasons,
  saveUserIndustries,
} from 'redux/reducers/appdata'

function* loadCountries() {
  const result = yield call(CALLAPI, { url: 'countries', method: 'get' })
  let sortedCountry = result ? result.sort(function (a, b) {
    var x = a.title.toLowerCase();
    var y = b.title.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  }) : [];
  yield put(saveCountries(sortedCountry))
}
function* loadSectorTrees() {
  const result = yield call(CALLAPI, { url: 'sectors/tree', method: 'get' })
  yield put(saveSectorTrees(result))
}

function* loadSectorL1s() {
  const result = yield call(CALLAPI, { url: 'sectors?level=1', method: 'get' })
  yield put(saveSectorsL1(result))
}
function* loadHearFromSources() {
  const result = yield call(CALLAPI, { url: 'hearing_sources', method: 'get' })
  yield put(saveHearFromSources(result))
}
function* loadMailingTypes() {
  const result = yield call(CALLAPI, { url: 'mailing_types', method: 'get' })
  yield put(saveMailingTypes(result))
}
function* loadOccupations() {
  const result = yield call(CALLAPI, { url: 'occupations', method: 'get' })
  yield put(saveOccupation(result))
}
function* loadAnnualIncomes() {
  const result = yield call(CALLAPI, { url: 'annual_incomes', method: 'get' })
  yield put(saveAnnualIncomes(result))
}
function* loadTotalNetWorths() {
  const result = yield call(CALLAPI, { url: 'total_net_worths', method: 'get' })
  yield put(saveTotalNetWorths(result))
}
function* loadWealthSources() {
  const result = yield call(CALLAPI, { url: 'wealth_sources', method: 'get' })
  yield put(saveWealthSources(result))
}
function* loadEducations() {
  const result = yield call(CALLAPI, { url: 'educations', method: 'get' })
  yield put(saveEducations(result))
}
function* loadNoTinReasons() {
  const result = yield call(CALLAPI, { url: 'no_tin_reasons', method: 'get' })
  yield put(saveNoTinReasons(result))
}
function* loadUserIndustries() {
  const result = yield call(CALLAPI, { url: 'user_industries', method: 'get' })
  yield put(saveUserIndustries(result))
}

function* loadRegions() {
  const result = yield call(CALLAPI, { url: 'regions', method: 'get' })
  yield put(saveRegions(result))
}

function* loadRegionsCountries() {
  const result = yield call(CALLAPI, { url: 'regions/countries', method: 'get' })
  yield put(saveRegionsCountries(result))
}
function* loadContactUsTypes() {
  const result = yield call(CALLAPI, { url: 'contact_types', method: 'get' })
  yield put(saveContactUsTypes(result ? result : []))
}
function* loadMetrics() {
  const result = yield call(CALLAPI, { url: 'metrics', method: 'get' })
  yield put(saveMetrics(result ? result : []))
}
function* loadFactors() {
  const result = yield call(CALLAPI, { url: 'factors', method: 'get' })
  yield put(saveFactors(result ? result : []))
}

function* loadThemes() {
  const result = yield call(CALLAPI, { url: 'stocks/thematic/themes', method: 'get' })
  yield put(saveThemes(result?.data || []))
}
function* loadQuestionnaireGradeOptions() {
  const result = yield call(CALLAPI, { url: 'grades', method: 'get' })
  yield put(saveQuestionnaireGradeOptions(result))
}
export default function* loadAppData() {
  yield take(loadInitData)
  yield fork(loadCountries)
  yield fork(loadSectorTrees)
  yield fork(loadSectorL1s)
  yield fork(loadRegions)
  yield fork(loadRegionsCountries)
  yield fork(loadContactUsTypes)
  yield fork(loadMetrics)
  yield fork(loadThemes)
  yield fork(loadFactors)
  yield fork(loadQuestionnaireGradeOptions)

  yield fork(loadHearFromSources)
  yield fork(loadMailingTypes)
  yield fork(loadOccupations)
  yield fork(loadAnnualIncomes)
  yield fork(loadTotalNetWorths)
  yield fork(loadWealthSources)
  yield fork(loadEducations)
  yield fork(loadNoTinReasons)
  yield fork(loadUserIndustries)

}