import { Request } from "Utils/Request"
import { API_BASE_URL } from 'config.js';
const getStocks = (params, onSuccess, onFail, options) => {
    return Request({ url: API_BASE_URL + 'stocks', method: 'get', params: params }, onSuccess, onFail, options);
}
const getStockByID = (params, onSuccess, onFail, options) => {
    return Request({ url: API_BASE_URL + 'stocks/' + params.id, method: 'get' }, onSuccess, onFail, options);
}

const getStocksBusinessYears = (params, onSuccess, onFail, options) => {
    return Request({ url: `${API_BASE_URL}stocks/${params.id}/business/years`, method: 'get' }, onSuccess, onFail, options);
}
const getStocksBusinessTypeYear = (params, onSuccess, onFail, options) => {
    return Request({ url: `${API_BASE_URL}stocks/${params.id}/business/${params.type}/${params.year}`, method: 'get' }, onSuccess, onFail, options);
}
const getStocksSalesCompanyGuidance = (params, onSuccess, onFail, options) => {
    return Request({ url: `${API_BASE_URL}stocks/factors/company_guidance/sales`, method: 'get', params }, onSuccess, onFail, options);
}
const getStocksEpsCompanyGuidance = (params, onSuccess, onFail, options) => {
    return Request({ url: `${API_BASE_URL}stocks/factors/company_guidance/eps`, method: 'get', params }, onSuccess, onFail, options);
}
const getStocksEbitdaCompanyGuidance = (params, onSuccess, onFail, options) => {
    return Request({ url: `${API_BASE_URL}stocks/factors/company_guidance/ebitda`, method: 'get', params }, onSuccess, onFail, options);
}
const getStocksQualityScores = (params, onSuccess, onFail, options) => {
    return Request({ url: `${API_BASE_URL}stocks/quality_scores`, method: 'get', params }, onSuccess, onFail, options);
}
const getStocksTopMetrics = (params, onSuccess, onFail, options) => {
    return Request({ url: `${API_BASE_URL}stocks/top_metrics`, method: 'get', params }, onSuccess, onFail, options);
}
const getFactorDividenedEstimateChange = (params, onSuccess, onFail, options) => {
    return Request({ url: `${API_BASE_URL}stocks/factors/dividend_estimates_changes`, method: 'get', params }, onSuccess, onFail, options);
}
const getStocksFactorEquityIssuances = (params, onSuccess, onFail, options) => {
    return Request({ url: `${API_BASE_URL}stocks/factors/equity_issuances`, method: 'get', params }, onSuccess, onFail, options);
}
const getStocksPredictionsQActual = (params, onSuccess, onFail, options) => {
    return Request({ url: `${API_BASE_URL}stocks/predictions/q_actual`, method: 'get', params }, onSuccess, onFail, options);
}
const getStocksFactorsAbnormalCorporateInvestments = (params, onSuccess, onFail, options) => {
    return Request({ url: `${API_BASE_URL}stocks/factors/abnormal_corporate_investments`, method: 'get', params }, onSuccess, onFail, options);
}
const getStocksPredictionsQAI = (params, onSuccess, onFail, options) => {
    return Request({ url: `${API_BASE_URL}stocks/predictions/q_ai`, method: 'get', params }, onSuccess, onFail, options);
}
const getStocksCompetitors = (params, onSuccess, onFail, options) => {
    return Request({ url: `${API_BASE_URL}stocks/${params.id}/competitors`, method: 'get', params }, onSuccess, onFail, options);
}
const getStocksPartners = (params, onSuccess, onFail, options) => {
    return Request({ url: `${API_BASE_URL}stocks/${params.id}/partners`, method: 'get', params }, onSuccess, onFail, options);
}
const getStocksSuppliers = (params, onSuccess, onFail, options) => {
    return Request({ url: `${API_BASE_URL}stocks/${params.id}/suppliers`, method: 'get', params }, onSuccess, onFail, options);
}
const getStocksCustomers = (params, onSuccess, onFail, options) => {
    return Request({ url: `${API_BASE_URL}stocks/${params.id}/customers`, method: 'get', params }, onSuccess, onFail, options);
}
const getThemesWatchlist = (params, onSuccess, onFail, options) => {
    return Request({ url: API_BASE_URL + 'themes/watchlist', method: 'get', params }, onSuccess, onFail, options);
}
export default {
    getStocks,
    getStockByID,
    getStocksBusinessYears,
    getStocksBusinessTypeYear,
    getStocksSalesCompanyGuidance,
    getStocksEpsCompanyGuidance,
    getStocksEbitdaCompanyGuidance,
    getStocksQualityScores,
    getStocksTopMetrics,
    getFactorDividenedEstimateChange,
    getStocksPredictionsQActual,
    getStocksFactorEquityIssuances,
    getStocksFactorsAbnormalCorporateInvestments,
    getStocksPredictionsQAI,
    getStocksCompetitors,
    getStocksPartners,
    getStocksSuppliers,
    getStocksCustomers,
    getThemesWatchlist
}