import { createSlice } from '@reduxjs/toolkit';

const initialState = {

  PAYMENT_GATEWAYS: [],
  PRICES: [],
  TRANSACTION_TYPES: [],
  PLANS: [],
  FEATURES: [],
  DISCOUNTS: [],
  EXCHANGE_RATES: [],
  FUND_BALANCE: [],
  CREDIT_BALANCE: null,
  PAYMENTS: [],
  METRIX_TABLE: null,
}

export const slice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    savePaymentGateways: (state, { payload: data }) => {
      return { ...state, PAYMENT_GATEWAYS: data }
    },
    savePrices: (state, { payload: data }) => {
      return { ...state, PRICES: data }
    },
    saveTransactionTypes: (state, { payload: data }) => {
      return { ...state, TRANSACTION_TYPES: data }
    },
    savePlans: (state, { payload: data }) => {
      return { ...state, PLANS: data }
    },
    saveFeatures: (state, { payload: data }) => {
      return { ...state, FEATURES: data }
    },
    saveDiscounts: (state, { payload: data }) => {
      return { ...state, DISCOUNTS: data }
    },
    saveExchangeRates: (state, { payload: data }) => {
      return { ...state, EXCHANGE_RATES: data }
    },
    saveFundBalance: (state, { payload: data }) => {
      return { ...state, FUND_BALANCE: data }
    },
    saveCreditBalance: (state, { payload: data }) => {
      return { ...state, CREDIT_BALANCE: data }
    },
    savePaymentHistory: (state, { payload: data }) => {
      return { ...state, PAYMENTS: data }
    },
    saveMetrixTable: (state, { payload: data }) => {
      return { ...state, METRIX_TABLE: data }
    },
  },
});
// Exports
export const {
  savePaymentGateways,
  savePrices,
  saveTransactionTypes,
  savePlans,
  saveFeatures,
  saveDiscounts,
  saveExchangeRates,
  saveFundBalance,
  saveCreditBalance,
  savePaymentHistory,
  saveMetrixTable,
} = slice.actions


export default slice.reducer;