import { Hidden } from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from "@material-ui/core/Typography";
import CloseIcon from '@material-ui/icons/Close';
import Search from "@material-ui/icons/Search";
import Button from 'components/CustomButtons/Button';
import Companies from "components/Header/SearchComponents/Companies";
import React, { memo, useState } from 'react';
import Tab from 'views/Shared/Tab';
import commonStyle from "assets/jss/common/common";

const useStyles = makeStyles((theme) => ({
    paperInDlg: {
        overflowX: 'hidden'
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: '1rem',
        flex: 1,
        color: 'white'
    },
    colorPrimary: {
        color: 'white'
    },
    searchBox: {
        width: '100%'
    },
    searchIconButton: {
        margin: 0
    }
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function SearchBoxComponent(props) {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileOpen, setMobileOpen] = useState(false)

    const buttonComponent = props.buttonComponent

    const TabContent = (props) => <Tab tabs={[
        // {
        //     title: 'BROKERS',
        //     content: <Brokers />
        // },
        // {
        //     title: 'SECTORS',
        //     content: <Sectors />
        // },
        {
            title: 'Search by ticker or company name',
            content: <Companies {...props} />
        },
    ]} />
    const handleClick = (event) => {
        setMobileOpen(true)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    const id = open ? 'simple-popover' : undefined;

    const renderButton = () => {
        if (props.buttonComponent) {
            return <div data-tut="search_nav" onClick={handleClick} style={{ cursor: 'pointer' }}>{buttonComponent}</div>
        } else {
            return <Button justIcon color='transparent' size='lg' onClick={handleClick}
                className={classes.searchIconButton}
                data-tut="search_nav" >
                <Search />
            </Button>
        }
    }
    return <div className={classes.searchBox}>

        {renderButton()}
        <Hidden smDown>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div style={{ width: '60rem' }}>
                    <TabContent onClose={handleClose} />
                </div>
            </Popover>
        </Hidden>
        <Hidden mdUp>
            <Dialog fullScreen open={mobileOpen} onClose={() => setMobileOpen(false)} TransitionComponent={Transition}
                classes={{
                    paper: classes.paperInDlg
                }}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" className={classes.colorPrimary} onClick={() => setMobileOpen(false)} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Search
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className={classes.paddingContent}>
                    <TabContent onClose={handleClose} />
                </div>
            </Dialog>
        </Hidden>
    </div>;
}
SearchBoxComponent.defaultProps = {
    buttonComponent: null
}

export default memo(SearchBoxComponent)
